import { useQuery } from "react-query";
import axios from "axios";
import { IChannel } from "../../models/channel";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/dashboard/channel`;

export const useGetAvailableChannels = (workspaceId: string, token?: string) => {
    const apiPath = `${basePath}/${workspaceId}`;
    const queryKey = `getAvailableChannels-${token}`;

    return useQuery<IChannel[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<IChannel>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve(null);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};
