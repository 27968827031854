import { QueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { ITeam } from "../../models/team";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/dashboard/team`;

export const useGetTeamInformation = (workspaceId: string, token?: string) => {
    const apiPath = `${basePath}/${workspaceId}`;
    const queryKey = `getTeamInformation-${token}`;

    return useQuery<ITeam>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<ITeam>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve(null);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useSaveTeamSettings = (workspaceId: string, token: string | undefined, queryClient: QueryClient) => {
    return useMutation({
        mutationFn: (team: ITeam) => {
            return axios.patch<ITeam>(`${basePath}/${workspaceId}/${team.id}`, team, {
                headers: {
                    Authorization: token,
                },
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`getTeamInformation-${token}`);
        },
    });
};
