import React from "react";
import { useTranslation } from "react-i18next";
import { IScheduleWithChannelName } from "../../models/scheduleWithChannelName";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { DateTime } from "luxon";
import { EVERYDAY_CADENCE_VALUE, EVERYDAY_SELECTED_CADENCE_VALUE } from "../../constants/businessLogicConstants";

export interface IScheduledQuestionsListProps {
    schedules: IScheduleWithChannelName[];
    onScheduleDelete: (scheduleId: string) => void;
}

const ScheduledQuestionsList: React.FC<IScheduledQuestionsListProps> = ({ schedules, onScheduleDelete }) => {
    const { t } = useTranslation();

    const renderActions = (schedule: IScheduleWithChannelName) => {
        return (
            <div>
                <Button
                    className="w-min"
                    role="button"
                    severity="danger"
                    label={t("delete")}
                    rounded
                    onClick={() => {
                        onScheduleDelete(schedule.id);
                    }}
                ></Button>
            </div>
        );
    };

    const renderCadence = (schedule: IScheduleWithChannelName) => {
        if (schedule.cadenceDefinition === EVERYDAY_CADENCE_VALUE || schedule.cadenceDefinition === EVERYDAY_SELECTED_CADENCE_VALUE) {
            return t("cadenceEveryDay");
        }

        if (schedule.cadenceDefinition.length > 0) {
            return schedule.cadenceDefinition
                .split("")
                .map((day) => {
                    return t(`weekDay${day}`);
                })
                .join(", ");
        }

        return "";
    };

    const renderScheduledAt = (schedule: IScheduleWithChannelName) => {
        return DateTime.fromISO(schedule.scheduledAt).toJSDate().toLocaleTimeString();
    };

    return (
        <div className="p-0 border-round border-2 border-orange-200 min-h-full" data-testid="scheduled-questions-list">
            <DataTable value={schedules} tableStyle={{ minWidth: "50rem" }} emptyMessage={t("noScheduledQuestions")}>
                <Column field="channelName" header={t("channel")}></Column>
                <Column header={t("scheduledAt")} body={renderScheduledAt}></Column>
                <Column header={t("cadence")} body={renderCadence}></Column>
                <Column header={t("actions")} body={renderActions}></Column>
            </DataTable>
        </div>
    );
};

export default ScheduledQuestionsList;
