import "./App.css";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-orange/theme.css";
import { PrimeReactProvider } from "primereact/api";
import DefaultLayoutComponent from "./components/layout/defaultLayout";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignInComponent from "./components/auth/signIn";
import SignOutComponent from "./components/auth/signOut";
import TeamSettingsComponent from "./components/settings/teamSettings";
import { UserFeedbackContextProvider } from "./contexts/userFeedback/userFeedbackContext";
import DashboardComponent from "./components/dashboard/dashboard";
import InstallComponent from "./components/auth/install";
import AutomationDashboardComponent from "./components/automation/automationDashboard";
import PostInstallComponent from "./components/auth/postInstall";
import { useEffect } from "react";

const App = () => {
    useEffect(() => {
        document.title = "QOTD - Dashboard";
    });
    const queryClient = new QueryClient();
    const router = createBrowserRouter([
        {
            path: "/",
            element: <DefaultLayoutComponent />,
            children: [
                { path: "/team", element: <TeamSettingsComponent /> },
                { path: "/automate", element: <AutomationDashboardComponent /> },
                { path: "/", element: <DashboardComponent /> },
            ],
        },
        {
            path: "/signin",
            element: <SignInComponent />,
        },
        {
            path: "/post-install",
            element: <PostInstallComponent />,
        },
        {
            path: "/sign-out",
            element: <SignOutComponent />,
        },
        { path: "/install", element: <InstallComponent /> },
    ]);

    return (
        <QueryClientProvider client={queryClient}>
            <PrimeReactProvider>
                <UserFeedbackContextProvider>
                    <RouterProvider router={router} />
                </UserFeedbackContextProvider>
            </PrimeReactProvider>
        </QueryClientProvider>
    );
};

export default App;
