import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideNavbarComponent: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="flex flex-column gap-3 bg-white z-0 p-4 w-full lg:w-2 qotd-box-shadow" data-testid="main-navbar">
            <Button className="w-full" role="link" severity="info" label={t("teamSettings")} rounded onClick={() => navigate("/team")}></Button>
            <Button className="w-full" role="link" severity="info" label={t("automate")} rounded onClick={() => navigate("/automate")}></Button>
            <Button
                className="mt-auto"
                label={t("privacyPolicy")}
                link
                onClick={() => window.open("https://www.questionsotd.com/privacy-policy", "_blank")}
            />
        </div>
    );
};

export default SideNavbarComponent;
