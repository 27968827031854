import { useMutation } from "react-query";
import axios from "axios";
import { IUser } from "../../models/user";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/auth`;

export const useSignInWithSlack = () => {
    return useMutation({
        mutationFn: (authCode: string) => {
            return axios.post<IUser>(`${basePath}/signin?code=${authCode}`, null, { withCredentials: true });
        },
    });
};
