import React from "react";
import { useTranslation } from "react-i18next";

const DashboardComponent: React.FC = () => {
    const { t } = useTranslation();

    return <div className="flex flex-column gap-4">{t("welcomeToQOTD")}</div>;
};

export default DashboardComponent;
