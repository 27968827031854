import { Card } from "primereact/card";
import { useTranslation } from "react-i18next";
import logo from "../../assets/QOTD-new-logo.svg";
import SignInWithSlackButton from "../common/signInWithSlackButton";

const HomeComponent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Card className="m-3 w-full" data-testid="home">
            <div className="flex flex-column align-items-center gap-4">
                <img src={logo} alt={t("qotdLogoAltText")} className="h-auto w-14rem" />
                <label>{t("youNeedToSignIn")}</label>
                <SignInWithSlackButton></SignInWithSlackButton>
            </div>
        </Card>
    );
};

export default HomeComponent;
