import { useTranslation } from "react-i18next";
import logo from "../../assets/QOTD-new-logo.svg";
import { useAuth } from "../../hooks/useAuth";
import UserInformationDisplayComponent from "./userInformationDisplay";
import { useNavigate } from "react-router-dom";

const MainHeaderComponent: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isAuthenticated, userInformation } = useAuth();

    return (
        <div className="flex justify-content-between bg-orange-100 z-1 p-3 pr-6 pl-6 font-bold qotd-box-shadow" data-testid="main-header">
            <div className="flex align-items-center gap-3">
                {isAuthenticated && (
                    <img src={logo} alt={t("qotdLogoAltText")} onClick={() => navigate("/")} className="h-4rem w-auto cursor-pointer" />
                )}
                {t("dashboard")}
            </div>
            {isAuthenticated && <UserInformationDisplayComponent userInformation={userInformation}></UserInformationDisplayComponent>}
        </div>
    );
};

export default MainHeaderComponent;
