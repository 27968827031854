import React, { createContext } from "react";

export interface IEnvironmentContextValues {
    slackSignInURL: string | undefined;
}

export const EnvironmentContext = createContext<IEnvironmentContextValues | null>(null);

export function EnvironmentContextProvider(props: { value?: IEnvironmentContextValues; children?: React.ReactNode }) {
    const slackSignInURL = process.env.REACT_APP_SLACK_SIGN_IN_URL ?? "";

    return <EnvironmentContext.Provider value={props.value ?? { slackSignInURL }}>{props.children}</EnvironmentContext.Provider>;
}
