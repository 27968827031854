import React from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../hooks/useEnvironment";
import SlackButton from "./slackButton";

const SignInWithSlackButton: React.FC = () => {
    const { t } = useTranslation();
    const { slackSignInURL } = useEnvironment();

    const redirectToSlackForSignIn = () => {
        window.location.href = `${slackSignInURL}&redirect_uri=${window.location.origin}/signin`;
    };

    return slackSignInURL ? <SlackButton label={t("signInWithSlack")} onClick={redirectToSlackForSignIn}></SlackButton> : null;
};

export default SignInWithSlackButton;
