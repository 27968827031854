import React from "react";
import logo from "../../assets/QOTD-new-logo.svg";
import { useTranslation } from "react-i18next";
import SignInWithSlackButton from "../common/signInWithSlackButton";

const PostInstallComponent: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-auto flex-column justify-content-center align-items-center gap-4 h-full min-h-screen bg-primary-100">
            <img src={logo} alt={t("qotdLogoAltText")} className="h-auto w-14rem" />
            <div>{t("successfullyInstalled")}</div>
            <SignInWithSlackButton></SignInWithSlackButton>
        </div>
    );
};

export default PostInstallComponent;
