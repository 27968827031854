import React from "react";
import { useGetTeamInformation, useSaveTeamSettings } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import TeamSettingsForm from "./teamSettingsForm";
import { ITeam } from "../../models/team";
import { useUserFeedback } from "../../hooks/useUserFeedback";
import { useQueryClient } from "react-query";
import LoadingSpinner from "../common/loadingSpinner";

const TeamSettingsComponent: React.FC = () => {
    const { t } = useTranslation();
    const { accessToken, userInformation } = useAuth();
    const queryClient = useQueryClient();
    const { showSuccessMessage, showErrorMessage } = useUserFeedback();
    const { data, isLoading: isGetTeamInformationLoading } = useGetTeamInformation(userInformation?.workspaceId ?? "", accessToken);
    const { mutateAsync: saveTeamSettings, isLoading: isSaveTeamInformationLoading } = useSaveTeamSettings(
        userInformation?.workspaceId ?? "",
        accessToken,
        queryClient
    );
    const isLoading = isGetTeamInformationLoading || isSaveTeamInformationLoading;

    const handleSubmit = (updatedTeam: ITeam) => {
        saveTeamSettings(updatedTeam)
            .then(() => {
                showSuccessMessage(t("updateTeamSuccessMessage"));
            })
            .catch(() => {
                showErrorMessage(t("updateTeamErrorMessage"));
            });
    };

    return (
        <>
            <h1 className="font-bold mt-0 pt-0">{t("teamSettings")}</h1>
            <LoadingSpinner isLoading={isLoading}></LoadingSpinner>
            {!isLoading && data && <TeamSettingsForm team={data} onSave={handleSubmit}></TeamSettingsForm>}
        </>
    );
};

export default TeamSettingsComponent;
