import { Avatar } from "primereact/avatar";
import { TieredMenu } from "primereact/tieredmenu";
import { IUser } from "../../models/user";
import { useRef } from "react";
import { MenuItem } from "primereact/menuitem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface IUserInformationDisplayProps {
    userInformation: IUser | undefined;
}

const UserInformationDisplayComponent: React.FC<IUserInformationDisplayProps> = ({ userInformation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const menu = useRef<TieredMenu>(null);
    const items: MenuItem[] = [
        {
            label: t("signOut"),
            icon: "pi pi-sign-out",
            command: () => {
                navigate("/sign-out");
            },
        },
    ];

    const displayMenu = (event: any) => {
        if (menu && menu.current) {
            menu.current.toggle(event);
        }
    };

    return (
        <>
            <div
                className="flex gap-3 border-round p-2 cursor-pointer hover:bg-black-alpha-10"
                onClick={displayMenu}
                data-testid="user-information-display"
            >
                <div className="flex flex-column justify-content-center cursor-pointer">
                    <label className="cursor-pointer">
                        <b>{userInformation?.fullName}</b>
                    </label>
                    <label className="font-normal cursor-pointer">
                        <small>{userInformation?.workspaceName}</small>
                    </label>
                </div>
                <Avatar image={userInformation?.workspacePictureUri} size="large" shape="circle" className="border-2 cursor-pointer" />
            </div>
            <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
        </>
    );
};

export default UserInformationDisplayComponent;
