import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthenticateWithSlack } from "../../hooks/useAuthenticateWithSlack";

const SignInComponent: React.FC = () => {
    const { t } = useTranslation();
    useAuthenticateWithSlack();

    return (
        <div className="flex flex-auto flex-column justify-content-center align-items-center gap-0 h-full min-h-screen bg-primary-100">
            <div>{t("signingIn")}</div>
        </div>
    );
};

export default SignInComponent;
