import React, { useState } from "react";
import { ITeam } from "../../models/team";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

interface TeamSettingsFormProps {
    team: ITeam;
    onSave: (updatedTeam: ITeam) => void;
}

const TeamSettingsForm: React.FC<TeamSettingsFormProps> = ({ team, onSave }) => {
    const { t } = useTranslation();
    const [teamName, setTeamName] = useState<string>(team.name);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);

    const gatherFormInformationAndSave = () => {
        if (teamName.length > 0) {
            onSave({
                ...team,
                name: teamName,
            });
        }
    };

    const updateTeamNameValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsFormValid(e.target.value.length > 0);
        setTeamName(e.target.value);
    };

    return (
        <div className="pt-5 pb-5 flex flex-column gap-4">
            <div className="flex flex-column gap-2 w-20rem ">
                <label htmlFor="teamName">{t("teamName")}</label>
                <InputText
                    id="teamName"
                    value={teamName}
                    className="p-inputtext-lg"
                    aria-describedby="teamname-help"
                    role="textbox"
                    invalid={!isFormValid}
                    maxLength={25}
                    onChange={updateTeamNameValue}
                />
                {isFormValid ? (
                    <small id="teamname-help">{t("teamNameHelp")}</small>
                ) : (
                    <small id="teamname-help" className="text-red-500">
                        {t("teamNameRequired")}
                    </small>
                )}
            </div>
            <Button
                label={t("save")}
                size="large"
                className="max-w-10rem"
                severity="success"
                disabled={!isFormValid}
                onClick={gatherFormInformationAndSave}
            ></Button>
        </div>
    );
};

export default TeamSettingsForm;
