import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignInWithSlack } from "../api/apiClient";
import { useAuth } from "./useAuth";
import { AxiosResponse } from "axios";
import { IUser } from "../models/user";

export const useAuthenticateWithSlack = (): void => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get("code");

    const navigate = useNavigate();
    const { mutateAsync: signInWithSlackAsync } = useSignInWithSlack();
    const { isAuthenticated, setUserInformation, storeToken } = useAuth();

    const isAutheticatingWithCode = authCode !== null && authCode !== undefined && authCode.trim() !== "";

    const navigateToHome = () => {
        navigate("/");
    };

    const handleAuthError = (error: any) => {
        if (error.response) {
            if (error.response.status && error.response.status === 404) {
                navigate("/install");
            }
        }
    };

    useEffect(() => {
        if (!isAutheticatingWithCode || isAuthenticated) {
            navigateToHome();
        } else if (isAutheticatingWithCode) {
            signInWithSlackAsync(authCode)
                .then((response: AxiosResponse<IUser>) => {
                    storeToken(response.headers["auth-token"]);
                    setUserInformation(response.data);
                    navigateToHome();
                })
                .catch(handleAuthError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutheticatingWithCode, isAuthenticated, authCode, signInWithSlackAsync, setUserInformation, storeToken]);
};
