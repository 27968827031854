import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const SignOutComponent: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isAuthenticated, clearUserInformation } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            clearUserInformation();
        }

        navigate("/");
    }, [isAuthenticated, clearUserInformation, navigate]);

    return (
        <div className="flex flex-auto flex-column justify-content-center align-items-center gap-0 h-100 min-h-screen bg-primary-100">
            <div>{t("signingOut")}</div>
        </div>
    );
};

export default SignOutComponent;
