import { useContext } from "react";
import { EnvironmentContext, IEnvironmentContextValues } from "../contexts/environment/environmentContext";

export const useEnvironment = (): IEnvironmentContextValues => {
    const context = useContext(EnvironmentContext);

    if (!context) {
        throw new Error("useEnvironment must be used within a EnvironmentContextProvider");
    }

    return context;
};
