import { useContext } from "react";
import { UserFeedbackContext, IUserFeedbackContextValues } from "../contexts/userFeedback/userFeedbackContext";

export const useUserFeedback = (): IUserFeedbackContextValues => {
    const context = useContext(UserFeedbackContext);

    if (!context) {
        throw new Error("useUserFeedback must be used within a UserFeedbackContextProvider");
    }

    return context;
};
