import React from "react";
import { useTranslation } from "react-i18next";
import { useEnvironment } from "../../hooks/useEnvironment";
import SlackButton from "./slackButton";

const AddAppSlackButton: React.FC = () => {
    const { t } = useTranslation();
    const { slackSignInURL } = useEnvironment();

    const redirectToSlack = () => {
        window.location.href = `${slackSignInURL}`;
    };

    return slackSignInURL ? <SlackButton label={t("addToSlack")} onClick={redirectToSlack}></SlackButton> : null;
};

export default AddAppSlackButton;
