import React, { useState } from "react";
import { IChannel } from "../../models/channel";
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from "primereact/autocomplete";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { ICreateRecurringQuestionRequest } from "../../dtos/createRecurringQuestionRequest";
import { IMultiSelectItem } from "../../models/multiSelectItem";
import { DEFAULT_DAY_VALUES, EVERYDAY_CADENCE_VALUE } from "../../constants/businessLogicConstants";

export interface IAddScheduledQuestionFormProps {
    avaialbleChannels: IChannel[];
    onSubmit: (request: ICreateRecurringQuestionRequest) => void;
}

const AddScheduledQuestionForm: React.FC<IAddScheduledQuestionFormProps> = ({ avaialbleChannels, onSubmit }) => {
    const { t } = useTranslation();
    const [suggestions, setSuggestions] = useState<IChannel[]>([]);
    const [selectedChannel, setSelectedChannel] = useState<IChannel | undefined>(undefined);
    const [scheduleTime, setScheduleTime] = useState<Nullable<Date>>(new Date());
    const [isScheduledEveryDay, setIsScheduledEveryDay] = useState<boolean>(true);
    const [selectedDays, setSelectedDays] = useState<string[] | null>(null);
    const [isValid, setIsValid] = useState<boolean>(false);

    const daysOfTheWeek: IMultiSelectItem[] = [
        { name: t("weekDay0"), value: "0" },
        { name: t("weekDay1"), value: "1" },
        { name: t("weekDay2"), value: "2" },
        { name: t("weekDay3"), value: "3" },
        { name: t("weekDay4"), value: "4" },
        { name: t("weekDay5"), value: "5" },
        { name: t("weekDay6"), value: "6" },
    ];

    const search = (event: AutoCompleteCompleteEvent) => {
        const query = event.query;
        let filteredSuggestions: IChannel[] = avaialbleChannels.filter((channel) => {
            return channel.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });

        setSuggestions(filteredSuggestions);
    };

    const validateAndSubmit = async () => {
        if (!isFormValid()) {
            return;
        }

        let cadence = EVERYDAY_CADENCE_VALUE;

        if (!isScheduledEveryDay && selectedDays && selectedDays.length > 0) {
            cadence = selectedDays.join("");
        }

        onSubmit({
            teamId: (selectedChannel as IChannel).id,
            scheduleTime: scheduleTime as Date,
            cadence,
        });
    };

    const isFormValid = (): boolean => {
        return (
            selectedChannel !== undefined &&
            selectedChannel !== null &&
            scheduleTime !== undefined &&
            scheduleTime !== null &&
            (isScheduledEveryDay || (selectedDays !== null && selectedDays !== undefined && selectedDays.length > 0))
        );
    };

    const updateFormValidationState = (
        currentSelectedChannel: IChannel | undefined,
        currentScheduleTime: Nullable<Date>,
        currentIsScheduledEveryDay: boolean,
        curentSelectedDays: string[] | null
    ): void => {
        setIsValid(
            currentSelectedChannel !== undefined &&
                currentSelectedChannel !== null &&
                currentScheduleTime !== undefined &&
                currentScheduleTime !== null &&
                (currentIsScheduledEveryDay || (curentSelectedDays !== null && curentSelectedDays !== undefined && curentSelectedDays.length > 0))
        );
    };

    const validateAfterChannelChange = (e: AutoCompleteChangeEvent) => {
        setSelectedChannel(e.value);
        updateFormValidationState(e.value, scheduleTime, isScheduledEveryDay, selectedDays);
    };

    const validateAfterTimeChange = (e: any) => {
        setScheduleTime(e.value);
        updateFormValidationState(selectedChannel, e.value, isScheduledEveryDay, selectedDays);
    };

    const validateAfterSelectedDaysChange = (e: MultiSelectChangeEvent) => {
        setSelectedDays(e.value);
        updateFormValidationState(selectedChannel, scheduleTime, isScheduledEveryDay, e.value);
    };

    const enableDaySelectionWithDefaultValues = (e: InputSwitchChangeEvent) => {
        setIsScheduledEveryDay(e.value);

        if (!e.value) {
            setSelectedDays(DEFAULT_DAY_VALUES);
        } else {
            setSelectedDays(null);
        }

        updateFormValidationState(selectedChannel, scheduleTime, e.value, DEFAULT_DAY_VALUES);
    };

    return (
        <div className="pt-1 p-4 border-round border-2 border-orange-200">
            <label>
                <small>{t("addScheduledQuestion")}</small>
            </label>
            <div className="flex flex-column md:flex-row md:flex-wrap pt-5 gap-6">
                <span className="p-float-label p-inputtext-lg w-full md:w-auto">
                    <AutoComplete
                        inputId="channelAutocomplete"
                        field="name"
                        className="w-full md:w-auto min-w-min"
                        value={selectedChannel}
                        suggestions={suggestions}
                        completeMethod={search}
                        onChange={validateAfterChannelChange}
                        dropdown
                        forceSelection
                    />
                    <label htmlFor="channelAutocomplete">{t("selectChannel")}</label>
                </span>
                <span className="p-float-label p-inputtext-lg">
                    <Calendar
                        inputId="scheduleTimePicker"
                        className="w-full md:w-auto min-w-min"
                        value={scheduleTime}
                        onChange={validateAfterTimeChange}
                        timeOnly
                    />
                    <label htmlFor="scheduleTimePicker">{t("selectTime")}</label>
                </span>
                <div className="flex flex-column md:flex-row align-items-center gap-4">
                    <div className="flex align-items-center min-w-max">
                        <label htmlFor="cadence-everyday" className="mr-2">
                            {t("cadenceEveryDay")}
                        </label>
                        <InputSwitch
                            inputId="cadence-everyday"
                            name="everyDay"
                            onChange={enableDaySelectionWithDefaultValues}
                            checked={isScheduledEveryDay}
                        />
                    </div>
                    {!isScheduledEveryDay && (
                        <div className="flex flex-column align-items-center w-full">
                            <MultiSelect
                                inputId="daysOfTheWeekMultiselect"
                                value={selectedDays}
                                onChange={validateAfterSelectedDaysChange}
                                options={daysOfTheWeek}
                                disabled={isScheduledEveryDay}
                                placeholder={t("selectDays")}
                                data-testid="day-selector"
                                optionLabel="name"
                                optionValue="value"
                                display="chip"
                                className="flex align-items-center w-full md:w-18rem h-3rem max-w-full"
                            />
                        </div>
                    )}
                </div>
                <Button
                    label={t("save")}
                    size="large"
                    className="md:w-10rem"
                    severity="success"
                    onClick={validateAndSubmit}
                    role="button"
                    disabled={!isValid}
                ></Button>
            </div>
        </div>
    );
};

export default AddScheduledQuestionForm;
