import { QueryClient, useMutation, useQuery } from "react-query";
import axios from "axios";
import { ICreateRecurringQuestionRequest } from "../../dtos/createRecurringQuestionRequest";
import { IScheduleWithChannelName } from "../../models/scheduleWithChannelName";

const basePath = `${process.env.REACT_APP_API_BASE_PATH ?? ""}/dashboard/schedule`;

export const useGetSchedulesForWorkspace = (workspaceId: string, token?: string) => {
    const apiPath = `${basePath}/${workspaceId}`;
    const queryKey = `getSchedulesForWorkspace-${token}`;

    return useQuery<IScheduleWithChannelName[]>(
        queryKey,
        () => {
            if (token) {
                return axios
                    .get<IScheduleWithChannelName>(apiPath, {
                        headers: {
                            Authorization: token,
                        },
                    })
                    .then((res: any) => res.data);
            }

            return Promise.resolve(null);
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};

export const useCreateScheduledQuestion = (workspaceId: string, token: string | undefined, queryClient: QueryClient) => {
    return useMutation({
        mutationFn: (request: ICreateRecurringQuestionRequest) => {
            return axios.post(
                `${basePath}/${workspaceId}/${request.teamId}`,
                {
                    scheduleTime: request.scheduleTime,
                    cadence: request.cadence,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`getSchedulesForWorkspace-${token}`);
        },
    });
};

export const useDeleteScheduledQuestion = (workspaceId: string, token: string | undefined, queryClient: QueryClient) => {
    return useMutation({
        mutationFn: (scheduleId: string) => {
            return axios.delete(`${basePath}/${workspaceId}/${scheduleId}`, {
                headers: {
                    Authorization: token,
                },
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(`getSchedulesForWorkspace-${token}`);
        },
    });
};
