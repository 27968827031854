import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/QOTD-new-logo.svg";
import AddAppSlackButton from "../common/addAppToSlackButton";

const InstallComponent: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className="flex flex-column align-items-center justify-content-center gap-4 w-full min-w-screen h-full min-h-screen bg-primary-100">
            <img src={logo} alt={t("qotdLogoAltText")} className="h-auto w-14rem" />
            <label>{t("youNeedToInstallTheApp")}</label>
            <AddAppSlackButton></AddAppSlackButton>
        </div>
    );
};

export default InstallComponent;
