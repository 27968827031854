import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";

export interface ILoadingSpinnerProps {
    isLoading: boolean;
}

const LoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ isLoading }) => {
    return (
        <>
            {isLoading && (
                <div className="h-full w-full flex align-items-center" data-testid="loading-spinner">
                    <ProgressSpinner />
                </div>
            )}
        </>
    );
};

export default LoadingSpinner;
