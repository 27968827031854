import { useContext } from "react";
import { IAuthContextValues, AuthContext } from "../contexts/auth/authContext";

export const useAuth = (): IAuthContextValues => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within a AuthContextProvider");
    }

    return context;
};
