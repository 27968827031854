import React from "react";
import { Button } from "primereact/button";

export interface ISlackButtonProps {
    label: string;
    onClick: () => void;
}

const SlackButton: React.FC<ISlackButtonProps> = ({ label, onClick }) => {
    return <Button onClick={onClick} label={label} icon="pi pi-slack" className="max-w-max"></Button>;
};

export default SlackButton;
