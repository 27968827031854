import React from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../common/loadingSpinner";
import { useGetAvailableChannels, useCreateScheduledQuestion, useGetSchedulesForWorkspace } from "../../api/apiClient";
import { useAuth } from "../../hooks/useAuth";
import AddScheduledQuestionForm from "./addScheduledQuestionForm";
import { ICreateRecurringQuestionRequest } from "../../dtos/createRecurringQuestionRequest";
import { useUserFeedback } from "../../hooks/useUserFeedback";
import ScheduledQuestionsList from "./scheduledQuestionsList";
import { useQueryClient } from "react-query";
import { useDeleteScheduledQuestion } from "../../api/schedule/scheduleApiClient";
import { Message } from "primereact/message";

const AutomationDashboardComponent: React.FC = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { accessToken, userInformation } = useAuth();
    const { showSuccessMessage, showErrorMessage } = useUserFeedback();
    const { data: availableChannels, isLoading: getAvailableChannelsLoading } = useGetAvailableChannels(
        userInformation?.workspaceId ?? "",
        accessToken
    );
    const { data: existingSchedules, isLoading: getSchedulesIsLoading } = useGetSchedulesForWorkspace(
        userInformation?.workspaceId ?? "",
        accessToken
    );
    const { mutateAsync: saveScheduledQuestion, isLoading: saveScheduledQuestionIsLoading } = useCreateScheduledQuestion(
        userInformation?.workspaceId ?? "",
        accessToken,
        queryClient
    );
    const { mutateAsync: deleteScheduledQuestion, isLoading: deleteScheduledQuestionIsLoading } = useDeleteScheduledQuestion(
        userInformation?.workspaceId ?? "",
        accessToken,
        queryClient
    );

    const isLoading = getAvailableChannelsLoading || getSchedulesIsLoading || saveScheduledQuestionIsLoading || deleteScheduledQuestionIsLoading;

    const saveRecurringQuestion = (request: ICreateRecurringQuestionRequest) => {
        saveScheduledQuestion(request)
            .then(() => {
                showSuccessMessage(t("scheduledQuestionSaved"));
            })
            .catch(({ response }) => {
                showErrorMessage(response.data);
            });
    };

    const deleteRecurringQuestion = (scheduleId: string) => {
        deleteScheduledQuestion(scheduleId)
            .then(() => {
                showSuccessMessage(t("scheduledQuestionDeleted"));
            })
            .catch(({ response }) => {
                showErrorMessage(response.data);
            });
    };

    return (
        <>
            <h1 className="font-bold mt-0 pt-0">{t("automate")}</h1>
            <LoadingSpinner isLoading={isLoading}></LoadingSpinner>
            {(!availableChannels || availableChannels.length === 0) && !isLoading && (
                <Message className="justify-content-start" severity="info" text={t("addToChannelToSchedule")} />
            )}
            {!isLoading && availableChannels && availableChannels.length > 0 && (
                <div className="flex flex-column gap-3">
                    <h3>{t("scheduledQuestions")}</h3>
                    <Message className="justify-content-start" severity="info" text={t("basicPlansSingleAutomatedQuestion")} />
                    <AddScheduledQuestionForm avaialbleChannels={availableChannels} onSubmit={saveRecurringQuestion}></AddScheduledQuestionForm>
                    <ScheduledQuestionsList schedules={existingSchedules || []} onScheduleDelete={deleteRecurringQuestion}></ScheduledQuestionsList>
                </div>
            )}
        </>
    );
};

export default AutomationDashboardComponent;
