import React, { createContext } from "react";
import { IUser } from "../../models/user";
import useLocalStorageState from "use-local-storage-state";

export interface IAuthContextValues {
    isAuthenticated: boolean;
    userInformation: IUser | undefined;
    accessToken: string | undefined;
    setUserInformation: (userInformation: IUser) => void;
    storeToken: (token: string | undefined) => void;
    clearUserInformation: () => void;
}

export const AuthContext = createContext<IAuthContextValues | null>(null);

export function AuthContextProvider(props: { value?: IAuthContextValues; children?: React.ReactNode }) {
    const accessTokenCookieName = "authToken";
    const userInformationCookieName = "userInformation";
    const [accessToken, setAccessToken, { removeItem: removeAccessToken }] = useLocalStorageState<string | undefined>(accessTokenCookieName, {
        defaultValue: undefined,
    });
    const [userInformation, setUserInformationInStorage, { removeItem: removeUserInformation }] = useLocalStorageState<IUser | undefined>(
        userInformationCookieName,
        {
            defaultValue: undefined,
        }
    );
    const isAuthenticated: boolean = accessToken !== undefined && userInformation !== undefined;

    const setUserInformation = (userInformation: IUser) => {
        setUserInformationInStorage(userInformation);
    };

    const storeToken = (token: string | undefined) => {
        if (token === undefined) {
            removeAccessToken();
        } else {
            setAccessToken(token);
        }
    };

    const clearUserInformation = () => {
        removeAccessToken();
        removeUserInformation();
    };

    return (
        <AuthContext.Provider
            value={props.value ?? { isAuthenticated, userInformation, accessToken, setUserInformation, storeToken, clearUserInformation }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export function StubbedAuthContextProvider(props: {
    isAuthenticated?: boolean;
    userInformation?: IUser;
    accessToken?: string;
    setUserInformation?: (userInformation: IUser) => void;
    storeToken?: (token: string | undefined) => void;
    clearUserInformation?: () => void;
    children?: React.ReactNode;
}) {
    const mockedValues: IAuthContextValues = {
        isAuthenticated: props.isAuthenticated ?? false,
        userInformation: props.userInformation,
        accessToken: props.accessToken,
        setUserInformation: props.setUserInformation ?? (() => {}),
        storeToken: props.storeToken ?? (() => {}),
        clearUserInformation: props.clearUserInformation ?? (() => {}),
    };

    return <AuthContext.Provider value={mockedValues}>{props.children}</AuthContext.Provider>;
}
